import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const Container = styled.div`
  position: relative;
  display: grid;
`
const StyledImage = styled(GatsbyImage)`
  grid-area: 1/1;
  img {
    max-height: 450px;
    aspect-ratio: 16/9;
  }
`
const Overlay = styled.div`
  position: relative;
  place-items: flex-end;
  grid-area: 1/1;
  display: grid;
`
const FeaturedImage = ({ image, alt, children, ...rest}) => {
  return (
    <Container>
      <StyledImage 
        image={image}
        alt={alt}
        {...rest}
      />
      <Overlay>
        {children}
      </Overlay>
    </Container>
  )
}

export default FeaturedImage
