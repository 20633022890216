import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Seo from '../components/seo'
import FeaturedImage from '../components/reviews-template/FeaturedImage'
import Navigation from '../components/reviews-template/Navigation'

const Container = styled.section`
  padding: 4rem 1rem;
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding: 4rem 0;
  }
`
const Header = styled.header`
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #efeee0;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`
const TitleContainer = styled.div`
  color: ${({ theme }) => theme.accent};
  line-height: 0.5em;
`
const Variant = styled.small`
  padding-left: 0.15rem;
  letter-spacing: normal;
  font-size: 0.45em;
`
const Score = styled.p`
  padding: 0.5rem;
  background: ${({ theme }) => theme.accent};
  font-size: 0.75em;
  opacity: 0.7;
`
const Body = styled.body`
  margin: 1rem 0;
  line-height: 1.5;
`
const ReviewPostTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { previous, next } = data

  return (
    <>
      <Container itemScope itemType='http://schema.org/Article'>
        <Seo
          title={frontmatter.title}
          description={frontmatter.description || data.markdownRemark.excerpt}
        />
        <Header>
          <TitleContainer>
            <h1 itemProp='headline'>{frontmatter.title}</h1>
            <Variant>{frontmatter.variant}</Variant>
          </TitleContainer>
          <FeaturedImage
            image={frontmatter.featuredImage.childImageSharp.gatsbyImageData}
            alt=''>
            <Score>{frontmatter.score}</Score>
          </FeaturedImage>
        </Header>
        <p>{frontmatter.title}</p>
        <p>{frontmatter.variant}</p>
        <p>{frontmatter.brewery}</p>
        <p>Score: {frontmatter.score} / 5</p>
        <Body
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          itemProp='articleBody'
        />
        <hr />
        <footer>
          <p>{frontmatter.date}</p>
        </footer>
      </Container>
      <Navigation previous={previous} next={next} />
    </>
  )
}

export default ReviewPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousReviewId: String
    $nextReviewId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        variant
        type
        brewery
        score
        description
        date(formatString: "MMMM DD, YYYY")
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousReviewId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextReviewId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
