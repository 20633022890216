import React from 'react'
import styled from 'styled-components'
import Link from '../common/link'

const Container = styled.nav`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  max-width: 600px;
  margin: 0 auto;
  grid-gap: 1rem;
`
const Button = styled(Link)`
  border: 1px solid ${({ theme }) => theme.contrast};
  padding: 0.5em;
  text-align: center;
  color: ${({ theme }) => theme.contrast};
`
const Navigation = ({ previous, next }) => {
  return (
    <Container>
      {previous ? (
        <Button to={previous.fields.slug}>
          ← {previous.frontmatter.title}
        </Button>
      ) : (
        <Button to={'/reviews/'}>Back to Review List</Button>
      )}
      {next ? (
        <Button to={next.fields.slug}>{next.frontmatter.title} →</Button>
      ) : (
        <Button to={'/reviews/'}>Back to Review List</Button>
      )}
    </Container>
  )
}

export default Navigation
